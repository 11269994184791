import clientStore from "./clientStore";
import userStore from "./usersStore";
import deviceStore from "./deviceStore";
import bpStore from "./bpStore";
import settingsStore from "./settingsStore";

export const rootStore = {
    clientStore,
    userStore,
    deviceStore,
    bpStore,
    settingsStore,
};

import { Select, Space } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../hooks/useConfig";

export const LangWidget = () => {
    const { i18n } = useTranslation();
    const { changeLocale } = useConfig();

    const localeOptions = [
        { label: "Ukrainian", title: "UA", value: "uk" },
        { label: "English", title: "EN", value: "en" }
    ];

    return (
        <Select
            value={i18n.resolvedLanguage}
            onChange={changeLocale}
            options={localeOptions}
            variant='borderless'
            size='large'
            popupMatchSelectWidth={false}
            labelRender={(option) => (
                <Space>
                    <GlobalOutlined />
                    {option.title}
                </Space>
            )}
        />
    );
};

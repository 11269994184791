import { createContext, useCallback, useDeferredValue, useEffect, useState } from "react";
import { ConfigProvider as ConfigProviderAntd, App as AppAntd, notification } from "antd";
import ukUA from "antd/es/locale/uk_UA";
import enUS from "antd/es/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/uk";
import { useTranslation } from "react-i18next";
import { LANG_STORAGE_KEY, THEME_STORAGE_KEY, themeTypes } from "helpers/consts";
import { themes } from "../theme";

export const ConfigContext = createContext();

const locales = {
    en: enUS,
    uk: ukUA
};

const prefersColorSchemeMedia = window.matchMedia("(prefers-color-scheme: dark)");

export const ConfigProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [api, contextHolder] = notification.useNotification();

    const [locale, setLocal] = useState(() => {
        const lang = localStorage.getItem(LANG_STORAGE_KEY);
        return locales[lang] || locales.uk;
    });

    const [themeKey, setThemeKey] = useState(() => {
        const isEnabledSystemDarkTheme = prefersColorSchemeMedia.matches;
        const systemTheme = isEnabledSystemDarkTheme ? themeTypes.DARK : themeTypes.LIGHT;
        return localStorage.getItem(THEME_STORAGE_KEY) || systemTheme;
    });

    const currentTheme = themes[themeKey] || themes[themeTypes.LIGHT];
    const themeDeferred = useDeferredValue(currentTheme);

    useEffect(() => {
        //listener change system theme
        const handleChangeSystemTheme = ({ matches }) => {
            const systemTheme = matches ? themeTypes.DARK : themeTypes.LIGHT;
            changeTheme(systemTheme);
        };
        prefersColorSchemeMedia.addEventListener("change", handleChangeSystemTheme);

        return () => prefersColorSchemeMedia.removeEventListener("change", handleChangeSystemTheme);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeTheme = useCallback((tKey) => {
        localStorage.setItem(THEME_STORAGE_KEY, tKey);
        setThemeKey(tKey);
        // document.documentElement.classList.toggle("dark")
    }, []);

    const changeLocale = useCallback(
        (lang) => {
            i18n.changeLanguage(lang);
            dayjs.locale(lang);
            setLocal(locales[lang]);
        },
        [i18n]
    );

    const openNotification = useCallback(
        (config) => {
            const {
                status = "open",
                message,
                description,
                withTranslate = true,
                ...otherConfig
            } = config;
            api[status]({
                message: t(message),
                description: withTranslate ? t(description) : description,
                ...otherConfig
            });
        },
        [api, t]
    );

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: t("required_field") + " ${label}"
    };

    return (
        <ConfigContext.Provider
            value={{
                locale,
                currentTheme: currentTheme.name,
                changeLocale,
                changeTheme,
                openNotification
            }}
        >
            <ConfigProviderAntd locale={locale} theme={themeDeferred} form={{ validateMessages }}>
                <AppAntd>
                    {contextHolder}
                    {children}
                </AppAntd>
            </ConfigProviderAntd>
        </ConfigContext.Provider>
    );
};

const TOKEN = "token";

class TokenService {
    #storage = null;

    constructor(type) {
        try {
            this.#storage = type === "local" ? window.localStorage : window.sessionStorage;
        } catch (error) {
            console.error(error);
        }
    }

    getRefreshToken() {
        const token = JSON.parse(this.#storage.getItem(TOKEN));
        return token?.refresh_token;
    }

    getAccessToken() {
        const user = JSON.parse(this.#storage.getItem(TOKEN));
        return user?.access_token;
    }

    setToken(data) {
        this.#storage.setItem(TOKEN, JSON.stringify(data));
    }

    updateAccessToken(token) {
        let user = JSON.parse(this.#storage.getItem(TOKEN));
        user.access_token = token;
        this.#storage.setItem(TOKEN, JSON.stringify(user));
    }

    removeToken() {
        this.#storage.removeItem(TOKEN);
    }

    parseJWTToken() {
        try {
            const accessToken = this.getAccessToken();
            if (!accessToken) {
                return null;
            }
            const tokenBody = accessToken.split(".")[1];
            return JSON.parse(window.atob(tokenBody));
        } catch (error) {
            if (this.#storage.getItem(TOKEN)) {
                this.removeToken();
            }
            return null;
        }
    }
}

export default new TokenService("session");

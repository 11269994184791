import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import * as mobx from "mobx";
import { SETTINGS_STORAGE } from "helpers/consts";

class SettingsStore {
    device_tree = {};
    device_selected_date = [];
    devices_tree = {};
    devices_selected_date = [];

    constructor() {
        makeAutoObservable(this);

        makePersistable(this, {
            name: SETTINGS_STORAGE,
            properties: ["device_tree", "devices_tree", "device_selected_date"],
            storage: window.localStorage,
        });
    }

    get DeviceTreeChecked() {
        return this.device_tree.checked;
    }

    get DevicesTreeChecked() {
        return this.devices_tree.checked || [];
    }

    get DevicesTreeExpanded() {
        return this.devices_tree.expanded || [];
    }

    get device_last_date() {
        return mobx.toJS(this.device_selected_date);
    }

    get devices_last_date() {
        return mobx.toJS(this.devices_selected_date);
    }

    clearDevicesTree = () => {
        this.devices_tree = {};
    }

    updateDeviceTreeChecked = (keys) => {
        this.device_tree.checked = keys;
    };

    updateDevicesTreeExpanded = (keys) => {
        this.devices_tree.expanded = keys;
    };
    updateDevicesTreeChecked = (keys) => {
        this.devices_tree.checked = keys;
    };

    update_device_selected_date = (values) => {
        this.device_selected_date = values;
    };

    update_devices_selected_date = (values) => {
        this.devices_selected_date = values;
    };
}

export default new SettingsStore();

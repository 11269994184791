import { createRoot } from "react-dom/client";
import { configure } from "mobx";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App/App";
import { StoresProvider } from "./context/storesContext";
import { ConfigProvider } from "./context/configProvider";
import "./i18n";
import "antd/dist/reset.css";
import "./assets/styles/index.css";

configure({
    enforceActions: "never" //change state in action
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <StoresProvider>
        <ConfigProvider>
            <Router>
                <App />
            </Router>
        </ConfigProvider>
    </StoresProvider>
);

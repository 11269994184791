import React from 'react'
import { rootStore } from "../store";

export const StoresContext = React.createContext();

export const StoresProvider = ({ children }) => {
    return <StoresContext.Provider value={rootStore}>{children}</StoresContext.Provider>;
};



import { Switch } from "antd";
import { MoonFilled, SunFilled } from "@ant-design/icons";
import { themeTypes } from "../../helpers/consts";
import { useConfig } from "../../hooks/useConfig";

export const ThemeWidget = () => {
    const { currentTheme, changeTheme } = useConfig();

    return (
        <Switch
            className='theme-switcher'
            checkedChildren={<MoonFilled style={{ color: "#ffb900" }} />}
            unCheckedChildren={<SunFilled />}
            checked={currentTheme === themeTypes.DARK}
            onChange={(isChecked) => changeTheme(isChecked ? themeTypes.DARK : themeTypes.LIGHT)}
        />
    );
};

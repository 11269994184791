import { theme } from "antd";
import { themeTypes } from "helpers/consts";

const colorPrimary = "#143960";
const colorPrimaryDark = "#386a9f";

const components = {
    Layout: {
        headerHeight: 60,
        headerPadding: 0
    }
};

export const themes = {
    light: {
        name: themeTypes.LIGHT,
        algorithm: theme.defaultAlgorithm,
        token: {
            colorPrimary
            // colorBgBase: "#c8c8c8"
        },
        components
    },
    dark: {
        name: themeTypes.DARK,
        algorithm: theme.darkAlgorithm,
        token: {
            colorPrimary: colorPrimaryDark
        },
        components: {
            ...components,
            Tree: {
                nodeSelectedBg: colorPrimaryDark
            }
        }
    }
};

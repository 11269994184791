import {googleAnalyticsConfig} from "../config";

export const THEME_STORAGE_KEY = "theme";
export const LANG_STORAGE_KEY = "i18nextLng";

export const themeTypes = {
    LIGHT: "light",
    DARK: "dark"
}

const setup = (...args) => {
    if (process.env.NODE_ENV !== "production") {
        return;
    }
    if (!window.gtag) {
        return;
    }
    window.gtag(...args);
};

export const track = {
    pageview: (props) => {
        setup('config', googleAnalyticsConfig, props);
    },
    event: (type, props) => {
        setup('event', type, props);
    }
};

export const METER_TYPES = ['em', 'hm', 'om']
export const METER_MODIFICATIONS = ['Eurosens Direct 100', 'NIK 2303', 'NIK 7051', 'NIK 7071']
export const METER_MANUFACTURER = ['nik', 'eurosens']
export const EXCLUDED_USERS = ["DataProvider"]

export const EM_OBIS = {
    "0.0.1.0.0.255": 'Поточний час (формат date-time)',
    "1.0.15.8.0.255": 'Активна енергія повна | A + | + |A-| (В т.ч)',
    "1.0.35.7.0.0": 'Активна потужність |A+|+|A-| фази А (Вт)',
    "1.0.32.7.0.0": 'Напруга фази А (мВ)',
    "1.0.31.7.0.0": 'Струм фази А (мА)',
    "1.0.55.7.0.0": 'Активна потужність |A+|+|A-| фази B (Вт)',
    "1.0.52.7.0.0": 'Напруга фази B (мВ)',
    "1.0.51.7.0.0": 'Струм фази B (мА)',
    "1.0.75.7.0.0": 'Активна потужність |A+|+|A-| фази C (Вт)',
    "1.0.72.7.0.0": 'Напруга фази C (мВ)',
    "1.0.71.7.0.0": 'Струм фази B (мА)'
}

export const CHARTS_COLORS = [
    '#263238',
    '#E74C3C',
    '#0E4DA4',
    '#1BB55C',
    '#E71561',
    '#FFBB12',
    '#FF161F',
    '#143960',
    '#67B930',
    '#009688',
    '#FF5722',
    '#9C27B0'
]
export const fakeData = [
    {
        "id": "japan",
        "color": "hsl(126, 70%, 50%)",
        "data": [
            {
                "x": "plane",
                "y": 210
            },
            {
                "x": "helicopter",
                "y": 137
            },
            {
                "x": "boat",
                "y": 34
            },
            {
                "x": "train",
                "y": 269
            },
            {
                "x": "subway",
                "y": 28
            },
            {
                "x": "bus",
                "y": 38
            },
            {
                "x": "car",
                "y": 213
            },
            {
                "x": "moto",
                "y": 135
            },
            {
                "x": "bicycle",
                "y": 255
            },
            {
                "x": "horse",
                "y": 44
            },
            {
                "x": "skateboard",
                "y": 202
            },
            {
                "x": "others",
                "y": 194
            }
        ]
    },
    {
        "id": "france",
        "color": "hsl(51, 70%, 50%)",
        "data": [
            {
                "x": "plane",
                "y": 29
            },
            {
                "x": "helicopter",
                "y": 85
            },
            {
                "x": "boat",
                "y": 43
            },
            {
                "x": "train",
                "y": 288
            },
            {
                "x": "subway",
                "y": 69
            },
            {
                "x": "bus",
                "y": 187
            },
            {
                "x": "car",
                "y": 249
            },
            {
                "x": "moto",
                "y": 24
            },
            {
                "x": "bicycle",
                "y": 165
            },
            {
                "x": "horse",
                "y": 146
            },
            {
                "x": "skateboard",
                "y": 41
            },
            {
                "x": "others",
                "y": 287
            }
        ]
    },
    {
        "id": "us",
        "color": "hsl(95, 70%, 50%)",
        "data": [
            {
                "x": "plane",
                "y": 208
            },
            {
                "x": "helicopter",
                "y": 101
            },
            {
                "x": "boat",
                "y": 94
            },
            {
                "x": "train",
                "y": 227
            },
            {
                "x": "subway",
                "y": 238
            },
            {
                "x": "bus",
                "y": 261
            },
            {
                "x": "car",
                "y": 81
            },
            {
                "x": "moto",
                "y": 297
            },
            {
                "x": "bicycle",
                "y": 59
            },
            {
                "x": "horse",
                "y": 50
            },
            {
                "x": "skateboard",
                "y": 295
            },
            {
                "x": "others",
                "y": 157
            }
        ]
    },
    {
        "id": "germany",
        "color": "hsl(295, 70%, 50%)",
        "data": [
            {
                "x": "plane",
                "y": 110
            },
            {
                "x": "helicopter",
                "y": 225
            },
            {
                "x": "boat",
                "y": 108
            },
            {
                "x": "train",
                "y": 120
            },
            {
                "x": "subway",
                "y": 236
            },
            {
                "x": "bus",
                "y": 16
            },
            {
                "x": "car",
                "y": 280
            },
            {
                "x": "moto",
                "y": 158
            },
            {
                "x": "bicycle",
                "y": 255
            },
            {
                "x": "horse",
                "y": 41
            },
            {
                "x": "skateboard",
                "y": 76
            },
            {
                "x": "others",
                "y": 281
            }
        ]
    },
    {
        "id": "norway",
        "color": "hsl(351, 70%, 50%)",
        "data": [
            {
                "x": "plane",
                "y": 204
            },
            {
                "x": "helicopter",
                "y": 102
            },
            {
                "x": "boat",
                "y": 138
            },
            {
                "x": "train",
                "y": 252
            },
            {
                "x": "subway",
                "y": 265
            },
            {
                "x": "bus",
                "y": 279
            },
            {
                "x": "car",
                "y": 182
            },
            {
                "x": "moto",
                "y": 118
            },
            {
                "x": "bicycle",
                "y": 64
            },
            {
                "x": "horse",
                "y": 253
            },
            {
                "x": "skateboard",
                "y": 266
            },
            {
                "x": "others",
                "y": 24
            }
        ]
    }
]

export const SETTINGS_STORAGE = "settings";

export const SELECTED_COMPANY_STORAGE = "selected_company"
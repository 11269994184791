import axios from "axios";
import TokenService from "./token.service";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const $http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        apikey: `${process.env.REACT_APP_API_KEY}`
    }
});
const $httpPapi = axios.create({
    baseURL: `${process.env.REACT_APP_PAPI_URL}`,
    headers: {
        apikey: `${process.env.REACT_APP_API_KEY}`
    }
});

$http.interceptors.request.use(addTokenToRequest);
$httpPapi.interceptors.request.use(addTokenToRequest);

// Instantiate the interceptor
createAuthRefreshInterceptor($http, refreshAuthLogic);
createAuthRefreshInterceptor($httpPapi, refreshAuthLogic);

function addTokenToRequest(config) {
    const token = TokenService.getAccessToken();
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
}

function refreshAuthLogic(failedRequest) {
    const refreshToken = TokenService.getRefreshToken();
    if (!refreshToken) {
        return failedRequest;
    }

    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/auth-refresh`,
            {},
            { headers: { Authorization: `Bearer ${refreshToken}` } }
        )
        .then((tokenRefreshResponse) => {
            const { access_token } = tokenRefreshResponse.data;
            TokenService.updateAccessToken(access_token);

            failedRequest.response.config.headers["Authorization"] =
                "Bearer " + TokenService.getAccessToken();
            return Promise.resolve();
        })
        .catch(() => {
            TokenService.removeToken();
            window.location.href = "/login";
        });
}

export { $httpPapi, $http };
import React from "react";
import { Route, Redirect } from "react-router-dom";
import tokenService from "../../services/token.service";

export const PrivateRoute = ({ component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            const currentUser = tokenService.parseJWTToken();
            if (!currentUser || !currentUser.sub || !currentUser.groups) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
            }

            if (roles && !isHasPermission(currentUser.groups, roles)) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: "/" }} />;
            }

            return React.createElement(component, props);
        }}
    />
);

function isHasPermission(userGroups, allowedGroups) {
    return userGroups.some((userRole) => allowedGroups.includes(userRole));
}

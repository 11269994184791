import React from "react";
import {Layout, theme} from "antd";
import {Navbar} from "./Navbar";

export default function MainLayout({children}) {
    const {token} = theme.useToken();

    const headerStyles = {
        background: token.colorBgContainer,
        boxShadow: `0px 5px 10px ${token.colorBorder}`,
        position: "sticky",
        top: 0,
        zIndex: 100
    };

    return (
        <Layout className='main-layout'>
            <Layout.Header style={headerStyles}>
                <Navbar />
            </Layout.Header>
            <Layout.Content className='main-layout__content'>
                <div className='container my-1'>{children}</div>
            </Layout.Content>
        </Layout>
    );
}

import { $http } from "./http.service";

export async function getGroups() {
    const res = await $http.get("/bp-groups");
    return res.data;
}

export async function deleteGroup(config) {
    const res = await $http.delete("/bp-group", config);
    return res.data;
}

export async function createGroup(payload) {
    const res = await $http.post("/bp-group", payload);
    return res.data;
}

export async function updateRole(payload) {
    const res = await $http.patch("/bp-group", payload);
    return res.data;
}

import { lazy } from "react";
import { roles } from "../helpers/permissions";

const privateRoutes = {
    home: {
        path: "/",
        exact: true,
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
        component: lazy(() => import("components/Devices"))
    },
    // allDevices: {
    //     path: "/devices",
    //     exact: true,
    //     roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
    //     component: lazy(() => import("components/Devices"))
    // },
    deviceNumber: {
        path: "/devices/:id",
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
        component: lazy(() => import("components/Device"))
    },
    unregisteredDevices: {
        path: "/unregistered-devices",
        exact: true,
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/UnregisteredDevices"))
    },
    users: {
        path: "/users",
        exact: true,
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Users"))
    },
    userAdd: {
        path: "/users/add",
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Users/AddUser"))
    },
    userEdit: {
        path: "/user/edit/:login",
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
        component: lazy(() => import("components/Users/EditUser"))
    },
    changePassword: {
        path: "/user/change-password",
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
        component: lazy(() => import("components/Users/ChangePassword"))
    },
    clients: {
        path: "/clients",
        exact: true,
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.OPERATOR],
        component: lazy(() => import("components/Clients"))
    },
    client: {
        path: "/clients/:id",
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.OPERATOR],
        component: lazy(() => import("components/Client"))
    },
    settingClients: {
        path: "/settings/clients",
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/ClientsTypes"))
    },
    settingDevices: {
        path: "/settings/modifications",
        exact: true,
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Modifications"))
    },
    settingDeviceEdit: {
        path: "/settings/modifications/edit/:id",
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Modifications/EditModification"))
    },
    deviceModificationAdd: {
        path: "/settings/modifications/add",
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Modifications/AddModification"))
    },
    settingUnits: {
        path: "/settings/units",
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Units"))
    },
    settingManufacturers: {
        path: "/settings/manufacturers",
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Manufacturers"))
    },
    ambusDevices: {
        path: "/ambus-devices",
        exact: true,
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/AmbusDevices"))
    },
};

export const getPrivateRoutes = Object.values(privateRoutes);

export const routePaths = Object.entries(privateRoutes).reduce((acc, [key, value]) => {
    return Object.assign(acc, { [key]: value.path });
}, {});

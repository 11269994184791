import React, { useLayoutEffect, useState } from "react";
import { Button, Space } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useConfig } from "hooks/useConfig";
import tokenService from "services/token.service";
import { themeTypes } from "helpers/consts";
import { LogoCompany } from "components/ui/LogoCompany/LogoCompany";
import { ThemeWidget } from "components/App/ThemeWidget";
import { LangWidget } from "components/App/LangWidget";
import { LoginModal } from "./LoginModal";
import "./Login.css";

export const Login = () => {
    const { currentTheme } = useConfig();
    const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();

    useLayoutEffect(() => {
        const currentUser = tokenService.parseJWTToken();
        if (currentUser) {
            history.replace("/");
        }
    }, [history]);

    const handleCloseLoginModal = () => {
        setIsOpenLoginModal(false);
    };

    return (
        <div
            className='login-page'
            style={{ background: currentTheme === themeTypes.LIGHT ? "#e2e2e2" : "#141414" }}
        >
            <LoginModal isOpen={isOpenLoginModal} onClose={handleCloseLoginModal} />
            
            <header className='login-page__header container'>
                <a href='/' className='logo'>
                    <LogoCompany fontSize={75} />
                </a>
                <Space size='large' style={{ marginLeft: "auto" }}>
                    <ThemeWidget />
                    <LangWidget />
                    <Button size='large' type='primary' onClick={() => setIsOpenLoginModal(true)}>
                        {t("signin")}
                    </Button>
                </Space>
            </header>
            <main className='login-page__main container'>
                <div className='login-page__block'>
                    <h2 className='login-page__title'>
                        <Trans i18nKey='auth_page_title'>
                            Комплексна система <br /> IT-рішень для управління вашими приладами
                        </Trans>
                    </h2>
                    <p className='login-page__text'>{t("auth_page_subtitle")}</p>
                </div>
            </main>
        </div>
    );
};

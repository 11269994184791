import { makeAutoObservable } from "mobx";
import * as mobx from "mobx";
import * as bpApi from "../services/bp.service";

class BpStore {
    mBpGroups = [];

    constructor() {
        makeAutoObservable(this);
    }

    get roles() {
        return mobx.toJS(this.mBpGroups);
    }

    fetchBpGroups = () => {
        bpApi
            .getGroups()
            .then((bpGroups) => {
                if (bpGroups) {
                    this.mBpGroups = bpGroups;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    removeRole = (id, callback) => {
        let config = {
            data: {
                id: id,
            },
        };
        bpApi
            .deleteGroup(config)
            .then((data) => {
                if (data.success) {
                    if (callback) {
                        callback();
                    }
                } else {
                    console.log("Error", data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    createGroup = async (payload) => {
        try {
            const response = await bpApi.createGroup(payload);
            if (response.success) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    updateRole = async (payload) => {
        try {
            const response = await bpApi.updateRole(payload);
            if (response.success) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };
}

export default new BpStore();

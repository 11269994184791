import React from 'react'

import './style.css'

class Card extends React.Component {

    render() {
        const {id, type, title, subtitle, refresh, bodyType, children, popup, className} = this.props;
        let rootClass= type ? `card ${type}` : 'card'
        rootClass += " " +className
        return (
            <div className={rootClass} id={id || ""}>
                {(title || subtitle) && (
                    <div className='card-header with-border'>
                        <div className='card-inner container py-1'>
                            {title && <div className='row d-flex align-items-center'>{title}</div>}
                            {subtitle && (
                                <div
                                    className='card-subtitle row'
                                    // dangerouslySetInnerHTML={{__html: subtitle}}
                                >
                                    {subtitle}
                                </div>
                            )}
                        </div>
                        {refresh && (
                            <div className='card-options'>
                                <div className='btn-list text-right'>
                                    {popup}
                                    {refresh}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className={bodyType || "card-body"}>{children}</div>
            </div>
        );
    };
}


export default Card;

import axios from "axios";
import { $http } from "./http.service";

export async function getUser(username) {
    const res = await $http.get(`/client-users?login=${username}`);
    return res.data;
}

export async function getUsers() {
    const res = await $http.get("/client-users");
    return res.data;
}

export async function deleteUser(id) {
    const res = await $http.delete("/client-user", { data: { id } });
    return res.data;
}

export async function upgradeToken({ client_id, user_role }) {
    const url = `${process.env.REACT_APP_API_URL}/auth-upgrade?client_id=${client_id}&role_code=${user_role}`;
    const res = await $http.post(url);
    return res.data;
}

export async function auth({ username, password }) {
    const response = await axios.post(
        process.env.REACT_APP_API_AUTH,
        {},
        {
            auth: {
                username: window.encodeURI(username),
                password,
            },
            headers: {
                apikey: `${process.env.REACT_APP_API_KEY}`,
            },
        }
    );
    return response.data;
}

export async function changePassword({ userId, password }) {
    const res = await $http.patch("/client-user-password", {
        id: userId,
        password: password,
    });
    return res.data;
}

export async function update(payload) {
    const res = await $http.patch("/client-user", payload);
    return res.data;
}

export async function create(payload) {
    const res = await $http.post("/client-user", payload);
    return res.data;
}

import React, { Fragment } from "react";
import { Navbar } from "./Navbar";
import Card from "../ui/Card";
import { withTranslation } from "react-i18next";
class ErrorBoundary extends React.Component {
    state = {
        error: "",
        errorInfo: "",
        hasError: false,
    };
    handleUpdate = () => {
        window.location.reload(false);
    };
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        console.log({ error, errorInfo });
        this.setState({ errorInfo });
    }
    render() {
        const { hasError, errorInfo } = this.state;

        if (hasError) {
            return (
                <Fragment>
                    <Navbar hideMenu />
                    <Card title={""} subtitle={""} bodyType='card-table'>
                        <div className='empty'>
                            <br />
                            <div className={"container"}>
                                <p className='empty-title h3'>
                                    {this.props.t("An error occurred. Try refreshing the page..")}
                                </p>
                                <p className='empty-subtitle text-muted'>
                                    {this.props.t("If the error is repeated, contact the administrator")}
                                </p>
                                <div className='empty-action text-center'>
                                    <button
                                        onClick={this.handleUpdate}
                                        type={"button"}
                                        href='#'
                                        className='btn btn-primary'
                                    >
                                        {this.props.t("Refresh the page")}
                                    </button>
                                </div>
                            </div>
                            <br />
                            <div className=''>
                                <div>
                                    <pre>{errorInfo && errorInfo.componentStack}</pre>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Fragment>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
export default withTranslation()(ErrorBoundary);

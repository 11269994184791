import { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import {FloatButton, Spin} from "antd";
import { SWRConfig } from "swr";
import MainLayout from "./MainLayout";
import ErrorBoundary from "./ErrorBoundary";
import { PrivateRoute } from "../Auth/PrivateRoute";
import { PageNotFound } from "../NotFoundComponent/Page404";
import { Login } from "../Auth/Login/Login";
import { getPrivateRoutes } from "../../routes";
import { useStores } from "hooks/useStores";
import { useConfig } from "hooks/useConfig";

export default function App() {
    const { userStore } = useStores();
    const { openNotification } = useConfig();

    useEffect(() => {
        userStore.fetchUser();
    }, [userStore]);

    const swrConfig = {
        revalidateOnFocus: false,
        throwOnError: false,
        errorRetryCount: 3,
        onError: (error) => {
            console.dir(error);
            openNotification({
                status: "error",
                message: "Error",
                description: error.response ? error.response.data.detail?.[0].msg : error.message
            });
        },
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            if (error.status < 500) return;
        },
    };

    return (
        <SWRConfig value={swrConfig}>
            <Suspense fallback='' /*Suspense for i18n*/>
                <ErrorBoundary>
                    <Switch>
                        <Route path='/login' component={Login} />
                        <Route path='/'>
                            <MainLayout>
                                <Suspense fallback={<Spin className='suspense-router-spin' />}>
                                    <Switch>
                                        {getPrivateRoutes.map((props) => (
                                            <PrivateRoute key={props.path} {...props} />
                                        ))}
                                        <Route path='*' component={PageNotFound} />
                                    </Switch>
                                </Suspense>
                            </MainLayout>
                        </Route>
                    </Switch>

                    <FloatButton.BackTop />
                </ErrorBoundary>
            </Suspense>
        </SWRConfig>
    );
}

import { $httpPapi, $http } from "./http.service";

export async function getByParams({ manufacturer, type }) {
    const res = await $http.get(
        `/devices?manufacturer=${manufacturer}&type=${type}&modification=*&identification=*&limit=-1&start=1`
    );
    return { devices: res.data, status: res.status };
}

export async function getTypes() {
    const res = await $http.get("/device-types");
    return res.data;
}

export async function getDevices({ manufacturer, type, id }) {
    const res = await $http.get(
        `/devices?manufacturer=${manufacturer}&type=${type}&identification=${id}&modification=*`
    );
    return res.data;
}

export async function getDevice(id) {
    return await $httpPapi.get(`/devices/number/${id}`);
}

export async function getManufactures() {
    const res = await $http.get("/manufacturers");
    return res.data;
}

export async function getModifications() {
    const res = await $httpPapi.get("/devices/modifications");
    return { modifications: res.data, status: res.status };
}

export async function getModification(id) {
    const res = await $httpPapi.get(`/devices/modifications/${id}`);
    return { modification: res.data, status: res.status };
}

export async function createModification(payload) {
    const res = await $http.post("/device-modification", payload);
    return res.data;
}

export async function updateModification(payload) {
    const res = await $http.patch("/device-modification", payload);
    return res.data;
}

export async function deleteModification(id) {
    return await $httpPapi.delete(`/devices/modifications/${id}`);
}

export async function getDeviceProperties({ id, ...values }) {
    return await $httpPapi.post(`/values/${id}`, values);
}

export async function getDatatypes() {
    return await $httpPapi.get("/datatypes");
}

export async function addDatatype(values) {
    const res = await $httpPapi.post("/datatypes", values);
    return res.data;
}

export async function updateDatatype(values) {
    const res = await $httpPapi.put(`/datatypes/${values["id"]}`, values);
    return res.data;
}

export async function deleteDatatype(id) {
    const res = await $httpPapi.delete(`/datatypes/${id}`);
    return res.data;
}

export async function getReports(values) {
    return await $httpPapi.post("/reports", values, { responseType: "blob" });
}

export async function getUnregisteredDevices() {
    const res = await $httpPapi.get("/devices/all_devices");
    return res.data;
}

export async function getDevicesByParams({ manufacturerId, typeId, serialNumber }) {
    const res = await $httpPapi.get(
        `/devices/find_devices/${serialNumber}/${manufacturerId}/${typeId}`
    );
    return res.data;
}

import { Alert, Button, Form, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

export const SelectCompanyForm = (props) => {
    const { errorMessage, onSubmit, clearError, clients, isLoading } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();

    return (
        <Space direction='vertical' className='d-flex'>
            <Typography.Title level={5} type='secondary'>
                {t("Select the company in which you are registered")}:
            </Typography.Title>
            <Form form={form} onFinish={onSubmit} size='large'>
                <Form.Item
                    name='company'
                    rules={[
                        {
                            required: true
                        }
                    ]}
                >
                    <Select
                        placeholder={t("Select the company")}
                        options={clients}
                        fieldNames={{ label: "client_name", value: "client_id" }}
                    />
                </Form.Item>
                {errorMessage && (
                    <Form.Item>
                        <Alert
                            message={errorMessage}
                            type='error'
                            showIcon
                            closable
                            afterClose={clearError}
                        />
                    </Form.Item>
                )}
                <Form.Item>
                    <Button block type='primary' htmlType='submit' loading={isLoading}>
                        {t("Continue")}
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    );
};

import { Menu, Space } from "antd";
import Icon, {
    ApartmentOutlined,
    AppstoreOutlined,
    ProductOutlined,
    SettingOutlined,
    TeamOutlined
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { roles } from "../../helpers/permissions";
import { useStores } from "../../hooks/useStores";
import { routePaths } from "../../routes";
import { LangWidget } from "./LangWidget";
import { ProfileDropdown } from "./ProfileDropdown";
import { LogoCompany } from "../ui/LogoCompany/LogoCompany";
import { ThemeWidget } from "./ThemeWidget";
import { ReactComponent as RouterIcon } from "../../assets/images/HugeiconsRouter.svg";

export const Navbar = observer(({ hideMenu = false }) => {
    const { userStore } = useStores();
    const { t } = useTranslation();
    const location = useLocation();
    const selectedMenuItem = location.pathname.split("/").slice(0, 2).join("/");
    const userRole = userStore.UserRole;

    const getMenuItems = useMemo(() => {
        const menu_items = [
            {
                label: <Link to={routePaths.home}>{t("devices")}</Link>,
                key: routePaths.home,
                isPrivate: false,
                icon: <AppstoreOutlined />
            },
            {
                label: <Link to={routePaths.clients}>{t("clients")}</Link>,
                key: routePaths.clients,
                isPrivate: false,
                icon: <ApartmentOutlined />
            },
            {
                label: <Link to={routePaths.users}>{t("users")}</Link>,
                key: routePaths.users,
                isPrivate: userRole !== roles.SYS_ADMIN,
                icon: <TeamOutlined />
            },
            {
                label: (
                    <Link to={routePaths.unregisteredDevices}>
                        {t("devices_without_registration")}
                    </Link>
                ),
                key: routePaths.unregisteredDevices,
                isPrivate: userRole !== roles.SYS_ADMIN,
                icon: <ProductOutlined />
            },
            {
                label: <Link to={routePaths.ambusDevices}>{t("Routers")}</Link>,
                key: routePaths.ambusDevices,
                isPrivate: userRole !== roles.SYS_ADMIN,
                icon: <Icon component={RouterIcon} />
            },
            {
                label: t("settings"),
                key: "/settings",
                isPrivate: userRole !== roles.SYS_ADMIN,
                icon: <SettingOutlined />,
                children: [
                    {
                        label: <Link to={routePaths.settingClients}>{t("types_customers")}</Link>,
                        key: routePaths.settingClients
                    },
                    {
                        label: (
                            <Link to={routePaths.settingDevices}>{t("device_modifications")}</Link>
                        ),
                        key: routePaths.settingDevices
                    },
                    {
                        label: (
                            <Link to={routePaths.settingUnits}>{t("types_units_measurement")}</Link>
                        ),
                        key: routePaths.settingUnits
                    },
                    {
                        label: (
                            <Link to={routePaths.settingManufacturers}>
                                {t("manufacturers_devices")}
                            </Link>
                        ),
                        key: routePaths.settingManufacturers
                    }
                ]
            }
        ];
        return menu_items.map(({ isPrivate, ...other }) => {
            if (isPrivate) return null;
            return { ...other };
        });
    }, [userRole, t]);

    return (
        <div className='header__wrap container'>
            <div className='logo'>
                <a href='/'>
                    <LogoCompany fontSize={60} />
                </a>
            </div>
            {userRole && !hideMenu && (
                <>
                    <Menu
                        className='main-navbar'
                        mode='horizontal'
                        selectedKeys={[selectedMenuItem, location.pathname]}
                        items={getMenuItems}
                        style={{
                            borderBottom: "none",
                            flex: 1,
                            minWidth: 0
                        }}
                        triggerSubMenuAction='click'
                    />
                    <Space size='middle'>
                        <ThemeWidget />
                        <LangWidget />
                        <ProfileDropdown user={userStore.user} />
                    </Space>
                </>
            )}
        </div>
    );
});

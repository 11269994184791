import { makeAutoObservable } from "mobx";
import * as mobx from "mobx";
import tokenService from "../services/token.service";
import * as usersApi from "../services/users.service";
import { roles } from "helpers/permissions";

class UserStore {
    user = null;
    userCompany = null;
    allUsers = [];
    userError = null;
    loading = false;

    constructor() {
        makeAutoObservable(this, null, { autoBind: true });
    }

    get AllUsers() {
        return mobx.toJS(
            this.allUsers.filter((user) => !user.groups.includes(roles.DATA_PROVIDER))
        );
    }
    get UserLogin() {
        return mobx.toJS(this.user?.login);
    }
    get UserRole() {
        return mobx.toJS(this.user?.groups[0]);
    }

    get IsCurrentUser() {
        return this.user.login === this.userCompany.login;
    }

    get UserClients() {
        return this.user?.relations || [];
    }

    setUser = (user) => {
        this.user = user;
    };

    selectUserCompany(username) {
        this.userCompany = this.AllUsers.find((user) => user.login === username);
    }

    clearUserError() {
        this.userError = null;
    }

    fetchAllUsers = () => {
        usersApi
            .getUsers()
            .then((users) => {
                if (users) {
                    this.allUsers = users;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    removeUser = (id, onCallback) => {
        usersApi
            .deleteUser(id)
            .then((data) => {
                if (data.success) {
                    this.allUsers = this.allUsers.filter((user) => user.id !== id);
                }
                if (onCallback) {
                    onCallback(data.success);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    fetchUpgradeUserToken = async (client_id, user_role) => {
        this.loading = true;
        this.userError = null;
        try {
            const token = await usersApi.upgradeToken({ client_id, user_role });
            if (!token) {
                throw new Error();
            }

            tokenService.setToken(token);
            return true;
        } catch (error) {
            this.userError = "Error when selecting a company";
            return false;
        } finally {
            this.loading = false;
        }
    };

    fetchUserAuth = async (username, password, callback) => {
        this.loading = true;
        try {
            const token = await usersApi.auth({ username, password });
            if (token) {
                tokenService.setToken(token);
                const user = await this.fetchUser();
                if (!user) {
                    this.userError = "The user is not tied to any of the clients";
                    tokenService.removeToken();
                    return;
                }
                if (callback) {
                    callback(user.relations);
                }
            } else {
                this.userError = "invalid_grant";
            }
        } catch (error) {
            const { data, status } = error.response;
            if (status === 401 && data?.error === "invalid_grant") {
                this.userError = data.error;
            }
        } finally {
            this.loading = false;
        }
    };

    fetchUser = async () => {
        const { sub: username } = tokenService.parseJWTToken() || {};
        if (!username) {
            return null;
        }
        try {
            const data = await usersApi.getUser(username);
            if (!data || data.length === 0) {
                throw new Error("Data is empty");
            }

            const user = data[0];
            this.setUser(user);
            return user;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    fetchUserCompany = async (username) => {
        this.loading = true;
        try {
            const user = await usersApi.getUser(username);
            if (user) {
                this.userCompany = user[0];
            }
        } catch (error) {
        } finally {
            this.loading = false;
        }
    };

    changeUserPassword = (payload) => {
        this.loading = true;
        return usersApi
            .changePassword(payload)
            .then((response) => {
                if (response.success) {
                    return true;
                }
                return false;
            })
            .catch(() => {
                return false;
            })
            .finally(() => (this.loading = false));
    };

    updateUser = (payload) => {
        return usersApi
            .update(payload)
            .then((data) => {
                if (!data.success) return false;

                const userIndex = this.allUsers.findIndex((u) => u.id === payload.id);
                if (userIndex >= 0) {
                    this.allUsers[userIndex] = Object.assign(this.allUsers[userIndex], payload);
                }
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    createUser = async (payload) => {
        try {
            const data = await usersApi.create(payload);
            if (!data.success) {
                return {
                    status: "error",
                    message: data.reason.error.code
                };
            }
            return {
                status: "success"
            };
        } catch (error) {
            return {
                status: "error",
                code: error.message
            };
        }
    };
}
export default new UserStore();

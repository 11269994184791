import * as mobx from "mobx";
import { makeAutoObservable } from "mobx";
import * as clientApi from "../services/client.service";

class ClientStore {
    _clients = [];
    _clients_tree = [];
    _client_types = [];
    loading = false;
    errorTree = null;

    constructor() {
        makeAutoObservable(this);
    }

    get clients() {
        return mobx.toJS(this._clients);
    }

    fetchClients = () => {
        if (!this._clients.length) this.loading = true;
        clientApi
            .getClients()
            .then((r) => {
                if (r.status === 200) {
                    this._clients = r.data;
                }
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                this.loading = false;
            });
    };

    get clients_tree() {
        return mobx.toJS(this._clients_tree || []);
    }

    fetchClientsTree = () => {
        this.errorTree = null;
        if (!this._clients_tree.length) this.loading = true;
        return clientApi
            .getClientsTree()
            .then((r) => {
                if (r.status === 200) {
                    this._clients_tree = r.data;
                } else {
                    this.errorTree = "Пристроїв не знайдено.";
                }
            })
            .catch(() => {
                this.errorTree = "Помилка. Спробуйте оновити сторінку.";
            })
            .finally(() => {
                this.loading = false;
            });
    };

    removeClientTypes = (id, onCallback) => {
        clientApi
            .deleteGroup(id)
            .then((response) => {
                if (response.data.success) {
                    if (onCallback) {
                        onCallback();
                    }
                    this.fetchClientTypes();
                } else {
                    console.log("Error", response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    addNewClient = (client_info, callback) => {
        clientApi
            .addClient(client_info)
            .then(({ data }) => {
                if (data.success) {
                    const newClient = {
                        ...client_info,
                        id: data.reason.new_id,
                        type: this.client_types.find((type) => type.id === client_info.group_id)
                    };
                    this._clients = [newClient, ...this._clients];
                    this._clients_tree = [
                        { ...client_info, id: data.reason.new_id, objects: null },
                        ...this._clients_tree
                    ];
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    removeClient = async (clientId, callback) => {
        try {
            await clientApi.deleteClient(clientId);
            clientId = parseInt(clientId);
            this._clients = this._clients.filter((client) => client.id !== clientId);
            this._clients_tree = this._clients_tree.filter((client) => client.id !== clientId);
            if (callback) {
                callback();
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateExistClient = (client_info, callback) => {
        clientApi
            .updateExistClient(client_info)
            .then((response) => {
                if (response.data.success) {
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    get client_types() {
        return mobx.toJS(this._client_types);
    }

    fetchClientTypes = () => {
        clientApi
            .getClientTypes()
            .then((r) => {
                if (r.status === 200) {
                    this._client_types = r.data;
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    createClientType = (newType, onCallback) => {
        clientApi
            .addClientType(newType)
            .then(({ status, data: newClientType }) => {
                if (status === 200) {
                    this._client_types = [newClientType, ...this._client_types];
                    if (onCallback) {
                        onCallback("New client type successfully created");
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    updateClientType = (existType, onCallbak) => {
        clientApi
            .updateExistType(existType)
            .then(({ data: updatedClientType }) => {
                this._client_types = this._client_types.map((clientType) => {
                    if (clientType.id === existType.id) {
                        return updatedClientType;
                    }
                    return clientType;
                });
                if (onCallbak) {
                    onCallbak("The client type has been updated successfully");
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    deleteClientType = (id, onCallback) => {
        clientApi
            .removeType(id)
            .then(({ status }) => {
                if (status === 200) {
                    this._client_types = this._client_types.filter(
                        (clientType) => clientType.id !== id
                    );
                    if (onCallback) {
                        onCallback();
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    updateGroup = async (group) => {
        try {
            const response = await clientApi.updateGroup(group);
            if (response.data.success) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    fetchClientRouters = async (clientId) => {
        this.loading = true;
        try {
            console.log(clientId);
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    };
}

export default new ClientStore();

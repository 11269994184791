import React, { useState } from "react";
import { Avatar, Dropdown, Popconfirm } from "antd";
import Icon, { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { routePaths } from "../../routes";
import { useTranslation } from "react-i18next";
import tokenService from "../../services/token.service";
import { ReactComponent as PasswordUserIcon } from "../../assets/images/SolarLockPasswordLinear.svg";
import { SETTINGS_STORAGE, SELECTED_COMPANY_STORAGE } from "helpers/consts";

export function ProfileDropdown({ user }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const userLogin = user.login;

    const onLogout = () => {
        setOpen(false);
        tokenService.removeToken();
        localStorage.removeItem(SETTINGS_STORAGE);
        localStorage.removeItem(SELECTED_COMPANY_STORAGE);
        window.location.href = "/login";
    };

    const items = [
        {
            label: <Link to={`/user/edit/${userLogin}`}>{t("profile")}</Link>,
            key: `/user/edit/${userLogin}`,
            icon: <UserOutlined />
        },
        {
            label: <Link to={routePaths.changePassword}>{t("change_password")}</Link>,
            key: routePaths.changePassword,
            icon: <Icon component={PasswordUserIcon} />
        },
        {
            label: (
                <Popconfirm
                    title={t("Are you sure?")}
                    okText={t("Yes")}
                    cancelText={t("No")}
                    onConfirm={onLogout}
                    onCancel={() => setOpen(false)}
                    placement='bottom'
                >
                    <a
                        href='/logout'
                        onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                        }}
                    >
                        {t("logout")}
                    </a>
                </Popconfirm>
            ),
            key: "exit",
            icon: <LogoutOutlined />,
            danger: true
        }
    ];

    return (
        <Dropdown
            menu={{
                items,
                onClick: ({ key }) => {
                    if (key === "exit") return;
                    setOpen(false);
                }
            }}
            placement='bottomRight'
            trigger={["click"]}
            // arrow={{ pointAtCenter: true }}
            open={open}
            onOpenChange={setOpen}
        >
            <Avatar size='large' shape='square' alt={userLogin} style={{ cursor: "pointer" }}>
                {user.name.at(0).toUpperCase()}
            </Avatar>
        </Dropdown>
    );
}

export const roles = {
    SYS_ADMIN: "sys-admin",
    ADMIN: "admin",
    OPERATOR: "operator",
    DATA_PROVIDER: "data-provider",
    ROOMER: "roomer"
};

export const permissions = {
    EDIT_CLIENT: "is it possible to edit the client",
    REMOVE_CLIENT: "remove-client",
    ADD_CLIENT: "add new client",
    EDIT_PROFILE: "edit-profile",
    EXPORT_REPORT: "export-report",
    ADD_USER: "add-user",
    REMOVE_USER: "remove-user",
};

export const rolePermissions = {
    [roles.SYS_ADMIN]: "*",
    [roles.ADMIN]: [
        permissions.EDIT_PROFILE,
        permissions.EXPORT_REPORT,
        permissions.EDIT_CLIENT,
        permissions.ADD_USER,
        permissions.REMOVE_USER
    ],
    [roles.OPERATOR]: [permissions.EXPORT_REPORT, permissions.EDIT_CLIENT],
    [roles.DATA_PROVIDER]: [permissions.EXPORT_REPORT]
};

export const isHasPermission = (role, permission) => {
    if (rolePermissions[role] === "*") return true;

    return (rolePermissions[role] || []).includes(permission);
};
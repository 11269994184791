import { Form, Button, Input, Alert } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export const LoginAuthForm = ({ onSubmit, errorMessage, clearError, isLoading }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        if (errorMessage) {
            form.setFields([
                { name: "username", errors: [""] },
                { name: "password", errors: [""] }
            ]);
        }
    }, [errorMessage, form]);

    return (
        <Form form={form} onFinish={onSubmit} size='large' validateTrigger='onBlur'>
            <Form.Item
                name='username'
                rules={[
                    {
                        required: true
                    }
                ]}
            >
                <Input prefix={<UserOutlined />} placeholder={t("login")} />
            </Form.Item>
            <Form.Item
                name='password'
                rules={[
                    {
                        required: true
                    }
                ]}
            >
                <Input.Password
                    prefix={<LockOutlined className='login-page__auth-icon' />}
                    placeholder={t("password")}
                />
            </Form.Item>
            {errorMessage && (
                <Form.Item>
                    <Alert
                        message={t(errorMessage)}
                        type='error'
                        showIcon
                        closable
                        afterClose={clearError}
                    />
                </Form.Item>
            )}
            <Form.Item>
                <Button type='primary' htmlType='submit' block loading={isLoading}>
                    {t("enter")}
                </Button>
            </Form.Item>
        </Form>
    );
};
